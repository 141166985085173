const $ = require('jquery');
global.$ = global.jQuery = $;

require('slick-carousel');
require('slick-carousel/slick/slick.css');
require('slick-carousel/slick/slick-theme.css');

require('bootstrap');

//loading
$(window).on('load', function() {
    $('body').addClass('is-loaded');
    setTimeout(() => {
        $('.loading').remove();
    }, 300);
});

//header
$(function() {
    $(window).on('load scroll', function() {
        if ($(this).scrollTop() < 220) {
            $('body').addClass('is-top');
        } else {
            $('body').removeClass('is-top');
        }
    });

    var header = $(".ec-layoutRole__header");
    $(window).on("load scroll", function () {
        if ($(window).width() >= 768) {
            if ($(this).scrollTop() >= 660 && header.hasClass("is-fixed") == false) {
                header.css({ top: "-220px" });//headerの高さ分上に設定
                header.addClass("is-fixed");
                header.animate({ top: 0 }, 500);//位置を0に設定し、アニメーションのスピードを指定
            } else if ($(this).scrollTop() < 440 && header.hasClass("is-fixed") == true) {
                header.removeClass("is-fixed");
            }
        }
    });
});


//draggable
$(function() {
    $.prototype.draggable = function() {
        var t;
        $(this).each(function(i,e) {
            $(e).mousedown(function(e2) {
            e2.preventDefault();
            t = $(e);
            $(e).data({
                down: true,
                x: e2.clientX,
                y: e2.clientY,
                left: $(e).scrollLeft(),
                top: $(e).scrollTop()
            });
            });
        });
        $(document).mousemove(function(e) {
            if($(t).data("down")) {
            e.preventDefault();
            $(t).scrollLeft($(t).data("x")+$(t).data("left")-e.clientX);
            $(t).scrollTop($(t).data("y")+$(t).data("top")-e.clientY);
            }
        }).mouseup(function(e) {
            $(t).data("down", false);
        });
    };
    $('[data-type="draggable_field"]').draggable();
});

//accordion
$(function() {
    $(document).on('click','.js-accordion',function(){
        $(this).toggleClass('is-active');
        $(this).next().slideToggle();
    });
});

//category
$(function() {
    const categoryHeading = $('.side-category .side-heading')
    if ($(window).width() >= 768) {
        categoryHeading.next().show();
    } else {
        categoryHeading.addClass('js-accordion');
    }
    $(window).on('resize', function(){
        if ($(window).width() >= 768) {
            categoryHeading.next().show();
            categoryHeading.removeClass('js-accordion');
        } else {
            categoryHeading.next().hide();
            categoryHeading.addClass('js-accordion');
        }
    });
    $('.side-category__listItem').click(function(){
        if ($(window).width() <= 767) {
            categoryHeading.removeClass('is-active');
            categoryHeading.next().hide('fast');

            const position = $('.top-productHeading').offset().top - 100;
            $('body,html').animate({scrollTop:position}, 'fast');
        }
    });
});
